import React from 'react';
import Parse from 'react-html-parser';
import { NavLink, useLocation } from 'react-router-dom';
import { useStore } from 'react-context-hook';
import checkIsActive from '../../helpers/checkIsActive';

const NavButton = ({ item }) => {
  const [region] = useStore('region');
  const { path, lxl_nav_title, slug } = item;
  const location = useLocation();
  const href = path ? path.replace(`/${region}`, '') : ''
  const props = {
    to: href,
    exact: true,
    activeClassName: 'active',
    className: `${slug} nav-item lexia-reg Label`,
    isActive: () => checkIsActive(href, location),
  };
  return <NavLink {...props}>{Parse(lxl_nav_title.desktop)}</NavLink>;
};

export default NavButton;
