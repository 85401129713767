import React, { useState } from 'react';
import { useStore } from 'react-context-hook';
import Parse from 'react-html-parser';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import { MOBILE } from '../../constants/mediaQueries';
import useMobile from '../../hooks/useMobile';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import Layout from './layout';

import { Footer } from '@lxlabs/az-pipeline-components';

const InnerSection = styled.div``;

const OuterSection = styled.div`
   flex-grow: 1;
   &.home-page {
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: auto 100%;
      background-position: center;
   }

   @media ${MOBILE} {
      &.disease-area-main-page {
         display: flex;
         flex-direction: column;
         justify-content: center;
         padding: 5vh 0;
         margin-top: 0;
         background-repeat: no-repeat;
         background-attachment: fixed;
         background-size: auto 100%;
         background-position: center;
      }
   }
`;

const FooterStyle = styled.div`
   z-index: 30;
   position: fixed;
   bottom: 0;
   width: 100%;
`;

const footerText = ({ colophon, approval_code, start_date, end_date, region }) => {
   let returnValue = '';

   if (colophon && region.includes('us')) {
      returnValue += `&copy;${new Date().getFullYear()} ${colophon} `;
   }
   else if (colophon) {
      returnValue += `&copy; ${new Date().getFullYear()} ${colophon} `;
   }

   if (start_date && region.includes('us')) {
      returnValue += `${approval_code} `;
   }
   else if (approval_code) {
      returnValue += `VPM ID: ${approval_code}. `;
   }

   if (start_date && region.includes('us')) {
      returnValue += `Last Updated ${start_date}`;
   }
   else if (start_date) {
      returnValue += `Date of preparation: ${start_date}`;
   }

   if (start_date && end_date) {
      returnValue += ', ';
   }

   return returnValue;
};

const PageTemplate = ({
   children,
   title,
   pageClass,
   content,
   noMargins,
   masthead,
   slug,
   references,
}) => {
   const [active, setActive] = useState(false);
   const isMobile = useMobile();
   const toggleNavigation = () => {
      setActive(!active);
   };

   const [region] = useStore('region');
   const site = require(`../../_data/${region}/lxl_site.json`);

   return (
      <>
         <Header
            active={active}
            masthead={masthead}
            toggleNavigation={toggleNavigation}
         />
         <Layout>
            <Helmet>
               <title>{title}</title>
            </Helmet>
            <OuterSection className={pageClass}>
               <InnerSection
                  className={`${content && 'content-page'} ${
                     noMargins && 'no-margins'
                  }`}
               >
                  {children}
               </InnerSection>
            </OuterSection>
         </Layout>

         {!isMobile ? (
            <FooterStyle>
               <Footer
                  region={window.localStorage.getItem('lxl_region')}
                  text={Parse(
                     footerText({
                        colophon: site.colophon,
                        approval_code: site.approval_code,
                        start_date: site.start_date,
                        region,
                     })
                  ).toString()}
                  links={[
                     {
                        url:
                           'https://www.astrazeneca.com/legal-notice-and-terms-of-use.html',
                        label: 'Legal and terms of use',
                     },
                     {
                        url: 'https://www.globalprivacy.astrazeneca.com',
                        label: region.includes('us') ? 'Privacy Notice' : 'Privacy Policy',
                     },
                     {
                        url: 'https://policy.cookiereports.com/14be610b-en-gb.html',
                        label: region.includes('us') ? 'Cookie Notice' : 'Cookie Policy',
                     },
                     {
                        url:
                           'https://www.astrazeneca.com/our-company/contact-us.html ',
                        label: 'Contact Us',
                     }
                  ]}
               />
            </FooterStyle>
         ) : (
            <MobileNavigation />
         )}
      </>
   );
};

export default PageTemplate;
